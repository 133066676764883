import React from "react";
import "./Announcement.css"

function AnnouncementBookCard({name,img,url}) {
  return (
    <a className="CourseSliderCard" href={url} target="_blank" style={{backgroundColor:'rgb(250,250,250)'}}>
      <img alt={name} src={img} className="AnnouncementBookCardImage"></img>
    </a>
  );
}

export default AnnouncementBookCard;
