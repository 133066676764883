import React from "react";
import Slider from "react-slick";
import "./Announcement.css";
import { CopyIcon, NextIcon, PrevIcon } from "../../assets/svg";
import AnnouncementCard from "./AnnouncementCard";
import SlideShowCard from "../SlideShow/SlideShowCard";
import AnnouncementBookCard from "./AnnouncementBookCard";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      // className={className}
      style={{
        position: "absolute",
        height: "25px",
        width: "25px",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
        right: 10,
        top: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "10",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <NextIcon height={30} width={30} color={"lightgray"} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      // className={className}
      style={{
        position: "absolute",
        height: "25px",
        width: "25px",
        marginLeft: "auto",
        marginTop: "auto",
        marginBottom: "auto",
        left: 10,
        top: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "10",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <PrevIcon height={30} width={30} color={"lightgray"} />
    </div>
  );
}

function Announcement() {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    autoplay: true,
    autoplaySpeed: 9000,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <div style={{}}>
      <div className="SliderTitle" style={{ marginBottom: 10 }}>
        {" "}
        <span
        // style={{ marginBottom: "5px", marginTop: "5px", minHeight: "5vh"}}
        >
          Duyurular
        </span>
      </div>
      <Slider {...settings} className="CourseSliderContainer">
        {/* <CourseSlideCard
          url={"https://kubbealti.org.tr/kurs/uzaktan-egitim-farsca-baslangic-seviyesi"}
          img={require("../../assets/img/fars.png")}
          name={1}
        /> */}

        <AnnouncementBookCard
          url={"https://kubbealti.org.tr/kitap/gezi-hikayeleri"}
          img={require("../../assets/img/gezi hikayeleri.jpeg")}
          name={5}
        />
        <AnnouncementBookCard
          url={
            "https://kubbealti.org.tr/kitap/kubbealti-vakfi-husn-i-hat-katalogu"
          }
          img={require("../../assets/img/hatKatalogu.jpeg")}
          name={6}
        />

        <AnnouncementBookCard
          url={"https://kubbealti.org.tr/kitap/ayverdiler"}
          img={require("../../assets/img/ayverdiler.jpeg")}
          name={1}
        />
        <AnnouncementBookCard
          url={"https://kubbealti.org.tr/kitap/mektuplar-14"}
          img={require("../../assets/img/mektuplar14.png")}
          name={2}
        />
        <AnnouncementBookCard
          url={"https://kubbealti.org.tr/kitap/kadikoylu-yillarim"}
          img={require("../../assets/img/kadikoyluYillarim.jpeg")}
          name={3}
        />
        <AnnouncementBookCard
          url={"https://kubbealti.org.tr/kitap/dil-bahisleri-2"}
          img={require("../../assets/img/dilBahisleri.jpeg")}
          name={4}
        />

        <AnnouncementCard
          url={"http://emineisinsu.com/"}
          img={require("../../assets/img/emine_isinsu.jpg")}
          name={1}
        />
        {/* <SlideShowCard url={"https://kubbealti.org.tr/kitap/yetmisinden-sonra"} img={require("../../assets/img/yetmişindenSonra.jpeg")} name={2}/> */}
      </Slider>
    </div>
  );
}

export default Announcement;
